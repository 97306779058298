import { memo, forwardRef, useMemo } from "react";
import PropTypes from "prop-types";
import NextImage from "next/image";
import { twMerge } from "tailwind-merge";
import { cva } from "class-variance-authority";

import isStorybook from "sholdi-shared/lib/env/isStorybook";

import sholdiLoader, {
  SHOLDI_LOADER_PARAMS,
  shimmer,
  toBase64,
} from "./sholdiLoader";

import { getComponentProps } from "sholdi-shared/helpers/getComponentProps";
import { sizes as imageSizes } from "./variants";

const image = cva(
  "relative overflow-hidden w-full h-full max-w-full max-h-full",
  {
    variants: {
      size: imageSizes,
    },
  },
);

const Image = forwardRef((props, ref) => {
  const {
    src,
    width,
    height,
    layout = "fill",
    sizes = "(min-width: 75em) 33vw, (min-width: 48em) 50vw, 100vw",
    objectFit = "contain",
    objectPosition = "center",
    size,
    quality = 75,
    shape,
    alt,
    priority = false,
    loading,
    className: _className,
    ...otherProps
  } = props;
  const { componentProps, ...rest } = useMemo(
    () => getComponentProps(otherProps, SHOLDI_LOADER_PARAMS),
    [otherProps],
  );

  let imageLayout = (width && !layout) || (width && layout) ? null : true;

  const shapeClass = shape === "circle" ? "rounded-full" : "";

  const className = useMemo(
    () => twMerge(image({ shape, size }), shapeClass, _className),
    [shape, size, shapeClass, _className],
  );

  // Storybook placeholder behavior
  if (isStorybook()) {
    return (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        ref={ref}
        src={src || "public/placeholder.png"}
        alt={alt}
        className={className}
        {...rest}
      />
    );
  }

  // Next.js optimized image rendering
  return (
    <div ref={ref} className={className} {...rest}>
      <NextImage
        src={src || "public/placeholder.png"}
        alt={alt}
        width={width}
        height={height}
        loading={loading}
        priority={priority}
        sizes={sizes}
        quality={quality}
        placeholder={`data:image/svg+xml;base64,${toBase64(shimmer(width || 300, height || 300))}`}
        style={{
          objectFit,
          objectPosition,
          ...(componentProps?.style || {}),
        }}
        {...componentProps}
        loader={(args) =>
          sholdiLoader({
            ...otherProps,
            sizes,
            src,
            alt,
            ...args,
            ...(width ? { width } : {}),
          })
        }
        {...(imageLayout ? { fill: true } : {})}
      />
    </div>
  );
});

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  quality: PropTypes.number,
  layout: PropTypes.oneOf(["fill", "responsive", "fixed"]),
  className: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  shape: PropTypes.oneOf(["circle", "rectangle"]),
  placeholderImage: PropTypes.string,
  priority: PropTypes.bool,
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  sizes: PropTypes.string,
  loading: PropTypes.oneOf(["eager", "lazy"]),
};

Image.displayName = "Image";

export default memo(Image);
