import isStorybook from "sholdi-shared/lib/env/isStorybook";
import getEnv from "sholdi-shared/lib/env/getEnv";

const paramMapping = {
  w: "w_",
  h: "h_",
  q: "q_",
  bg: "background_color",
  ar: "ar_",
};

export const SHOLDI_LOADER_PARAMS = [
  "q",
  "w",
  "h",
  "bg",
  "background_color",
  "ar",
];

const normalizeSrc = (src) => (src[0] === "/" ? src.slice(1) : src);

export const getImageCdnUrl = () =>
  isStorybook()
    ? getEnv("STORYBOOK_SHOLDI_IMAGE_CDN_URL")
    : getEnv("NEXT_PUBLIC_SHOLDI_IMAGE_CDN_URL");

const getQualityTransformation = (q, quality) => {
  const qualityValue = q || quality || "75";
  return qualityValue === "auto" ? "q_75" : `q_${qualityValue}`;
};

const getBackgroundTransformation = (bg, background_color) =>
  bg || background_color ? `bg_${bg || background_color}` : "";

const getWidthHeightTransformations = (w, width, h, height, ar) => {
  const transformations = [];
  if (w || width) transformations.push(`w_${w || width}`);
  if (h || height) transformations.push(`h_${h || height}`);

  if (!w && !h && ar) transformations.push(`ar_${ar}`);
  else if (!w && !h) transformations.push("ar_1");

  return transformations;
};

const getOtherTransformations = (otherProps) =>
  Object.keys(otherProps).reduce((acc, param) => {
    if (paramMapping[param] && otherProps[param]) {
      return [...acc, `${paramMapping[param]}${otherProps[param]}`];
    }
    return acc;
  }, []);

export const sholdiLoader = (props) => {
  const {
    src,
    q,
    quality,
    w,
    width,
    h,
    height,
    bg,
    background_color,
    ar,
    ...otherProps
  } = props;

  if (src.includes("blob")) {
    return src;
  }

  const transformations = [
    ...getWidthHeightTransformations(w, width, h, height, ar),
    getQualityTransformation(q, quality),
    getBackgroundTransformation(bg, background_color),
    ...getOtherTransformations(otherProps),
  ];

  const transformedImagePath = `${transformations
    .filter(Boolean)
    .join(",")}/${normalizeSrc(src)}`;

  return `${getImageCdnUrl()}/${transformedImagePath}`;
};

// export const getBlurDataURL = (imageUrl) =>
//   `${getImageCdnUrl()}/w_50,ar_1,q_5/${imageUrl}`;

export const shimmer = (w, h) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#ffffff" offset="20%" />
      <stop stop-color="#ecf3fa" offset="50%" />
      <stop stop-color="#ffffff" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#ffffff" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

export const toBase64 = (str) =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

export default sholdiLoader;
